import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const EditImageButton = (props) => {
    const color = props.editingImage ? "red" : "darkslategrey"
    const borderOpts = props.editingImage ? "" : "1px solid grey"
    return ( 
        <Button
            aria-label="Change Image"
            component="div"
            style={{
                border: borderOpts,
                color: color,
                display: "block",
                marginLeft: "auto",
                marginBottom: "2em",
                textAlign: "center",
                width: "160px"
            }}
            onClick={props.editImage}
        >
          { props.editingImage == true
            ?   
                <span>Close <CloseIcon /></span>
            :   
                <span>Change Image <EditIcon /></span>
          }
        </Button>
     );
}

export default EditImageButton;