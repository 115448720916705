import React, { useState, useEffect } from "react";

const ImageDataBox = (props) => {
  // const [imageData, setImageData] = useState(null);
  // const [commentText, setcommentText] = useState(null);
  // const [room, setRoom] = useState();
  
  const { room, commentText } = props
  
  // Initialize comments in text box
  useEffect(() => {
    // setcommentText(props.text);
    // setRoom(props.room);
  }, []);
  
  const onRoomChange = (e) => {
    e.preventDefault()

    const roomName = e.target.value
    console.log("onRoomChange function was called")
    props.onImageDataChange({
      ...props.imageData,
      room: roomName
    })
  }

  // Update Comment
  const onCommentChange = (e) => {
    e.preventDefault()

    const commentText = e.target.value
    console.log("onRoomChange function was called")
    props.onImageDataChange({
      ...props.imageData,
      commentText: commentText
    })
  }
  



  return (
    <form>
      <div className="form-group">
        <label htmlFor="room" className="fw-semibold">Room (click to change)</label>
        <select className="form-control" id="room" value={props.imageData?.room || ""} onChange={(e) => onRoomChange(e)}>
          <option>Living Room</option>
          <option>Kitchen</option>
          <option>Garage</option>
          <option>Master Bedroom</option>
          <option>Office</option>
          <option>Bedroom #1</option>
          <option>Bathroom #1</option>
          <option>Bedroom #2</option>
          <option>Bathroom #2</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="exampleFormControlTextarea1" className="fw-semibold">Comments</label>
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          onChange={(e) => onCommentChange(e)}
          value={props.imageData?.commentText || ""}
        ></textarea>
      </div>
    </form>
  );
};

export default ImageDataBox;
