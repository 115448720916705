import { useState, useEffect } from 'react';
import QRCodeWidget from './QRCodeWidget';




const QRCodeGrid = () => {
    const [qrCodes, setqrCodes] = useState([]);
    
    useEffect(() => {
        fetch('https://3kf7cs3k0m.execute-api.us-east-1.amazonaws.com/getUserLabels?' + new URLSearchParams({
            CustomerId: '1'
        })
        )
        .then(res => res.json())
        .then(jsonData => setqrCodes(jsonData))
    }, []);

    const codes = qrCodes.map(code => {
        return (
            <QRCodeWidget
                data={code['LabelId']}
                key={code['LabelId']}
                />
        )
    })

    return (
        <div className="d-flex container-sm flex-wrap">
        {codes}
        </div>
      );
}
 
export default QRCodeGrid;