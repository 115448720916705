import "./App.css";
import "./scss/styles.js";
import { Link, Outlet } from "react-router-dom";
// import { Outlet } from "react-router-dom";
import QRCodeGrid from "./QRCodeGrid";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ImageUploadBtn from "./ImageUploadBtn";
import UploadImageToS3WithNativeSdk from "./UploadImageToS3WithNativeSdk";
import ImageInformationBox from "./ImageInformationBox";
import ImageDataBox from "./ImageDataBox";
import EditImageButton from "./EditImageButton";

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [imgURL, setImgURL] = useState(null);
  const [labelId, setLabelId] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [editingImage, setEditingImage] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  // Set LabelId
  useEffect(() => {
    const label = searchParams.get("LabelId");
    if (label != null) {
      setLabelId(label);
    }
    console.log(`label ${label}`);
  }, [searchParams]);

  // Get/set image url
  useEffect(() => {
    if (labelId != null) getImgURL(labelId);
  }, [labelId]);

  // Update DB when imageData changes
  useEffect(() => {
    if (imageData == null) return;

    const params = {
      Key: {
        CustomerId: "1",
        LabelId: labelId,
      },
      UpdateExpression: "set imageData = :val1",
      ExpressionAttributeValues: {
        ":val1": imageData,
      },
    };

    // Send payload to update dynamoDB
    fetch(
      "https://3kf7cs3k0m.execute-api.us-east-1.amazonaws.com/label_images",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    ).catch((error) => {
      console.warn(error);
    });
  }, [imageData]);

  // Helper to get and set image url for label
  const getImgURL = (qrLabelId) => {
    // DEBUG - Remove me before prod
    console.log("fetching the img url");
    fetch(
      "https://3kf7cs3k0m.execute-api.us-east-1.amazonaws.com/label_images?" +
        new URLSearchParams({
          CustomerId: "1",
          LabelId: qrLabelId,
        })
    )
      .then((res) => res.json())
      .then((jsonData) => {
        const imgUrl = jsonData["Item"]["imgURL"];
        console.log(`imgUrl ${imgUrl}`);
        setImgURL(imgUrl);
        if (jsonData["Item"]["imageData"])
          setImageData(jsonData["Item"]["imageData"]);
      });
  };

  // Helper to set state for editing image
  const handleClickEditImage = () => {
    setEditingImage(!editingImage);
  };

  const handleImageDataChange = (data) => {
    setImageData(data);
  };

  return (
    <div className="App">
      <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand text-light" href="#">
            PackPics
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon text-light"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Link className="text-decoration-none text-light mx-2" to="/tags">My Tags</Link>
              <Link className="text-decoration-none text-light mx-2" to="/printTags">Print Tags</Link>
            </ul>
          </div>
        </div>
      </nav>
      
      {labelId == null ? (
        <></>
      ) : (
        <div className="container-fluid col-10">
          {false ? (
            // If imgURL isn't yet set, show spinner
            <div
              className="spinner-border"
              role="status"
              style={{ marginTop: "50vh" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            // Otherwise, show the image
            <>
              <div className="mt-5 mb-5">
                <EditImageButton
                  editImage={handleClickEditImage}
                  editingImage={editingImage}
                />
              </div>
              {editingImage == true ? (
                <div className="d-flex justify-content-center">
                  <UploadImageToS3WithNativeSdk
                    LabelId={labelId}
                    setImgURL={setImgURL}
                    toggleEditingMode={handleClickEditImage}
                  />
                </div>
              ) : (
                <img
                  src={imgURL}
                  className="rounded mb-2 d-block mx-auto label-img"
                />
              )}
              {canEdit == true ? (
                <ImageDataBox
                  imageData={imageData}
                  onImageDataChange={handleImageDataChange}
                />
              ) : (
                <ImageInformationBox
                  title={imageData.title}
                  room={imageData.room}
                  comments={imageData.comments}
                />
              )}
            </>
          )}
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default App;
