import { useState, useEffect } from "react";


const customerId = '1'


export default function Images() {
    const [labelData, setLabelData] = useState({});
    const [images, setImages] = useState()
    console.log(`Running Images: ${JSON.stringify(labelData)}`)
    // Get all the codes for a customer
    useEffect(() => {
        fetch('https://3kf7cs3k0m.execute-api.us-east-1.amazonaws.com/getUserLabels?CustomerId=1' + new URLSearchParams({
            // CustomerId: '1'
        })
        )
            .then(res => res.json())
            .then(jsonData => {
                // console.log(`jsonData ${JSON.stringify(jsonData)}`)
                setLabelData(jsonData)
            })
    }, []);


    useEffect(() => {
        // try {
        if (labelData == null) {
            return
        }

        const prep =  (!Array.isArray(labelData)) 
            ? [labelData]
            : labelData
        
        setImages(prep.filter(
                el => el?.imgURL
            ).map(el => {
                return (
                    <div class="card col-10 col-lg-5">
                        <img className="img img-thumbnail card-img-top" src={el?.imgURL} key={el?.LabelId || Math.random()} />
                        <div class="card-body">
                            <h5 class="card-title">{el?.imageData?.room}</h5>
                            <p class="card-text">{el?.imageData?.commentText}</p>
                            <a href={'/userLabel?LabelId=' + el.LabelId} class="btn btn-primary">See More</a>
                        </div>
                    </div>
                    )
                })
            )

        // }
        // catch(e) {
        //     console.warn(e)
        //     setImages(Array.isArray(labelData) + '\n' + JSON.stringify(labelData))
        // }
        }, [labelData])

        return (
            <>
                <h1 className="display-1">{images?.length} Images</h1>
                <div className="container d-flex flex-wrap">
                    {images}
                </div>
            </>
    )
}

// export default Images;