const ImageInformationBox = (props) => {
  return (
    <div>
            <p id="title" className="display-4">{props.title}</p>
            <p id="room">{props.room}</p>
            <textarea className="form-control" id="comments" rows="8" value={props.comments} readOnly />
    </div>
  );
};

export default ImageInformationBox;
