import React, { useState } from "react";
import AWS from "aws-sdk";
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import Button from '@mui/material/Button';

const S3_BUCKET = "packingpix";
const REGION = "us-east-1";

const credentials = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
};

const myBucket = new S3Client({
  params: { Bucket: S3_BUCKET },
  region: REGION,
  credentials,
});


const cleanFileNames = (filename) => {
    return filename.replaceAll(' ', '_')
}

const UploadImageToS3WithNativeSdk = (props) => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedFilePreview(URL.createObjectURL(e.target.files[0]))
  };

  const uploadFile = async (file) => {
    const fileKey = props["LabelId"] + Date.now()

    // Set file upload configuration
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileKey,
    };

    // Create upload command
    const uploadCommand = new PutObjectCommand(params);
    // Send the upload command to S3
    const response = await myBucket.send(uploadCommand)
    

    // .on('httpUploadProgress', (evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err) => {
    //     if (err) console.log(err)
    // })

        // PRESIGNED URL
    // const getObjectCommand = new GetObjectCommand(params);
    // const url = await getSignedUrl(myBucket, getObjectCommand, {
    //   expiresIn: 3600 * 24,
    // });

    // Construct url to access image
    const url = `https://${S3_BUCKET}.s3.amazonaws.com/${fileKey}`
    
    // DEBUG - Remove me
    console.log(`url ${url}`);

    // Configure DynamoDB update parameters
    const data = {
      Key: {
        CustomerId: "1",
        LabelId: props["LabelId"],
      },
      UpdateExpression: "set imgURL = :val1",
      ExpressionAttributeValues: {
        ":val1": url,
      },
    };

    // Send payload to update dynamoDB
    fetch(
      "https://3kf7cs3k0m.execute-api.us-east-1.amazonaws.com/label_images",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then(data => {
        props.setImgURL(url)
    })

    // Close "change image" mode
    props.toggleEditingMode()
  };

  return (
    <div className="text-center">
      <label htmlFor="contained-button-file">
        <Button className="mx-4" variant="contained" color="primary" component="span" onChange={handleFileInput}>
          Select File
        </Button>
      </label>
      <input type="file" onChange={handleFileInput} id="contained-button-file" style={{ display: "none" }}/>
      
      <button 
      className="btn btn-success" 
      onClick={() => uploadFile(selectedFile)}
      style={{display: selectedFile == null ? "none" : "inline-block"}}
      >
        Upload
      </button>
      {/* Only show preview and filename if a file has been selected */}
      {
        selectedFile == null
          ? null
          :
            <>
              <p>{selectedFile.name}</p>
              <img className="img-thumbnail" src={selectedFilePreview} />
            </>
      }
    </div>
  );
};

export default UploadImageToS3WithNativeSdk;
