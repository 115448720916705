import React from "react";
import render from "react-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import QRCodeGrid from "./QRCodeGrid";
import App from "./App";
import LandingPage from "./routes/landingPage";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter,
    createBrowserRouter,
    Routes,
    Route,
    RouterProvider,
} from "react-router-dom";
import Images from "./routes/images";

const router = createBrowserRouter([{
        path: "/",
        element: < App / > ,
        children: [{
                path: "landing_page",
                element: < LandingPage / > ,
            },
            {
                path: "printTags",
                element: < QRCodeGrid / > ,
            },
            {
                path: "tags",
                element: < Images / > ,
            }
        ]
    },
    {
        path: "/userLabel",
        element: < App / >
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root")).render( <
    React.StrictMode >
    <
    RouterProvider router = { router }
    />{" "} < /
    React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();