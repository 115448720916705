import React, { useState, useEffect } from 'react';
import QRCode from "qrcode";


const QRCodeWidget = (props) => {
    const [QR, setQR] = useState();

    const generateQR = async(text) => {
        const domain = window.location.href.startsWith("http://localhost") 
            ? "http://mac-mini.local:3000" 
            : window.location.href.substr(0, window.location.href.indexOf('.salon') + 7)
        console.log(`domain ${domain}`);
        try {
            const urlString = domain + 'userLabel?' + new URLSearchParams({
                LabelId: text
            })
            return QRCode.toDataURL(urlString);
        } catch (err) {
            console.error(err);
        }
    }

        
    useEffect(() => {
        generateQR(props.data).then(data => setQR(data))    
    }, []);

    return (
        <div className="qr-wrapper col-5 col-sm-5 col-lg-4 mt-5">
            <img src={QR}
                // width="400"
                // height="400"
            />
        </div>
    )
}

export default QRCodeWidget;